<template>
  <v-dialog v-model="localDialog" width="500" persistent>
    <v-card rounded="lg" class="pb-5" color="#ffffff">  <!-- สีกรอบ -->
      <div class="img">
        <img src="../assets/ll.png" alt="" width="100" height="100" />
      </div>
      <div class="header text-center">
        <h4>REGISTER SUCCESS</h4>
        <h4>การสมัครสำเร็จ !</h4>
        <!-- <p>กรุณาบันทึก USERNAME และ รหัสผ่านด้านล่างเพื่อเข้าสู่ระบบเกมส์</p> -->
      </div>
      <div class="content text-center">
        <v-card dark color="#0487D9" class="px-2 d-flex flex-column py-3" :style="{ 'border-radius': '15px' }">  <!-- สีกรอบ -->
          <b>คุณ {{ userInfo.fristname }} {{ userInfo.lastname }}</b>
          <b>USER : {{ randomString.username }}</b>

          <div class="content text-center">
            <v-card dark color="#1c1c1caa" class="px-2 d-flex flex-column py-3 my-2" :style="{ 'border-radius': '20px', 'width': '100%' }">  <!-- สีกรอบ -->
              รหัสที่ใช้ Login
              <b>เบอร์โทร : {{ userInfo.phoneNumber }}</b>
              <b>รหัสผ่าน : {{ randomString.password }}</b>
            </v-card>
          </div>

          <b>เครดิตคงเหลือ : 100฿</b>
        </v-card>
      </div>
      <div class="d-flex justify-center mt-3"> <!-- สีปุ่ม -->
        <v-btn
          color="#56B0DB"
          dark
          @click="goToGame"
          width="70%"
          class="mt-2"
          rounded
        >
          <v-icon left> mdi-gamepad-variant </v-icon>
          เปิดใช้งาน USERNAME คลิก
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["dialog", "userInfo"],
  data: () => ({}),
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set() {
        return this.$emit("onCloseDialog");
      },
    },
    randomString() {
      const username =
        "1xb" + Math.floor(100000 + Math.random() * 99999);
      const password =
        Math.random().toString(36).substring(2, 4) +
        "0" + Math.floor(1000 + Math.random() * 9999);
      const login = { username, password };
      return login;
    },
  },
  methods: {
    goToGame() {
      this.$router.push({ name: "Game" });
    },
  },
};
</script>

<style lang="scss" scoped>
.img {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.header {
  padding: 0 30px;
  font-size: 13px;
  h4 {
    font-size: 20px;
    &:nth-child(1) {
      color: #56B0DB;
    }
  }
}
.content {
  padding: 0 30px;
  letter-spacing: 1px;
  & > * {
    margin: 5px 0;
  }
}
.button-custom {
  display: flex;
}
</style>