var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"register"},[_vm._m(0),_c('Model-bonus',{attrs:{"dialog":_vm.dialog,"userInfo":_vm.userInfo},on:{"onCloseDialog":_vm.closeDialog}}),_c('v-card',{staticClass:"py-5",attrs:{"width":"90%","max-width":"400","rounded":"xxl","color":"#0487D9"}},[_c('h2',{staticClass:"mb-2 text-center"},[_vm._v("สมัครสมาชิก")]),_c('v-form',{ref:"form",staticClass:"form-custom",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('p',[_vm._v("หมายเลขโทรศัพท์")]),_c('v-text-field',{ref:"form.phoneNumber",attrs:{"solo":"","flat":"","outlined":"","dense":"","required":"","counter":"","rules":[
          () => !!_vm.phoneNumber || 'โปรดเบอร์โทรศัทพ์',
          () =>
            (!!_vm.phoneNumber && _vm.phoneNumber.length === 10) ||
            'เบอร์มือถือไม่ถูกต้อง',
        ],"maxlength":"10","label":"หมายเลขโทรศัพท์","prepend-inner-icon":"mdi-phone"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('p',[_vm._v("ธนาคารสำหรับฝาก - ถอนเงิน "),_c('b',[_vm._v("*")])]),_c('v-select',{attrs:{"items":_vm.styles.bankName,"solo":"","outlined":"","dense":"","flat":"","rules":[() => !!_vm.bankName || 'โปรดเลือกธนาคาร'],"required":"","label":"เลือกธนาคาร"},model:{value:(_vm.bankName),callback:function ($$v) {_vm.bankName=$$v},expression:"bankName"}}),_c('p',[_vm._v("เลขที่บัญชี")]),_c('v-text-field',{attrs:{"solo":"","flat":"","outlined":"","dense":"","rules":[
          () => !!_vm.bankNumber || 'โปรดกรอกเลขที่บัญชี',
          () =>
            (!!_vm.bankNumber && _vm.bankNumber.length >= 10) ||
            'เลขที่บัญชีไม่ถูกต้อง',
        ],"required":"","maxlength":"15","label":"เลขที่บัญชี"},model:{value:(_vm.bankNumber),callback:function ($$v) {_vm.bankNumber=$$v},expression:"bankNumber"}}),_c('p',[_vm._v("ชื่อจริง")]),_c('v-text-field',{attrs:{"solo":"","flat":"","outlined":"","dense":"","rules":[() => !!_vm.fristname || 'โปรดกรอกชื่อ'],"required":"","label":"ชื่อจริง"},model:{value:(_vm.fristname),callback:function ($$v) {_vm.fristname=$$v},expression:"fristname"}}),_c('p',[_vm._v("นามสกุล")]),_c('v-text-field',{attrs:{"solo":"","flat":"","outlined":"","dense":"","rules":[() => !!_vm.lastname || 'โปรดกรอกนามสกุล'],"required":"","label":"นามสกุล"},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}}),_c('v-btn',{staticClass:"mb-4",attrs:{"x-large":"","type":"submit","color":"#56B0DB","dark":"","width":"100%"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" สมัครสมาชิก ")],1),_c('v-btn',{attrs:{"medium":"","color":"null","dark":"","width":"100%"},on:{"click":_vm.handleLogin}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-gamepad-variant")]),_vm._v(" เข้าสู่ระบบ ")],1)],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-web"},[_c('img',{attrs:{"src":require("../assets/ll.png"),"alt":"","width":"100%","height":"100%"}})])
}]

export { render, staticRenderFns }